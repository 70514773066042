<template>
  <v-layout wrap v-if="editedItem">
    <v-toolbar
      elevation="0"     
    >
      <v-icon large style="margin-right:10px;">{{ editedItem.ID ? 'mdi-file-edit' : 'mdi-file-plus' }}</v-icon>
      <v-toolbar-title>
        {{ editedItem.ID ? $t('folders.editFile', locale) : $t('folders.addFile', locale) }}
      </v-toolbar-title>
    </v-toolbar>
    <!-- <v-flex xs12>
      <h1>{{ editedItem.ID ? $t('folders.editFile', locale) : $t('folders.addFile', locale) }}</h1>      
    </v-flex> -->
    <v-flex xs12 style="padding:20px 20px 0 20px">
      <translate
        v-model="editedItem.Name"
        :languages="languages"
        :label="$t('folders.name', locale)"
        @input="handleChangeIsModified"
        type="text"
      />
    </v-flex>
    <v-flex xs12>
      <upload-file
        :info="editedItem.FileInfo"
        :translate="true"
        :onDeleteFile="handleDeleteFile"
        :onChange="handleUploadFile"
        @filesUploaded="handleUploadFile($event)"        
      />
    </v-flex>
    <v-flex xs12>      
      <action-buttons
        :id="editedItem.ID"
        :onDeleteItem="onDelete"
        :onSaveItem="handleSave"
        :onClose="onClose"
        :locale="locale"
      />
    </v-flex>
  </v-layout>
</template>
<script>
  import { mapState } from 'vuex'
  import api from '@/services/api'
  import Translate from '@/components/Translate'
  import ActionButtons from '@/components/ActionButtons'
  import UploadFile from '@/components/uploadFile/Index'
  // import pdf from 'vue-pdf'
  export default {
    name: 'folder-form',
    components: {
      Translate,
      ActionButtons,
      UploadFile,
      // pdf
    },
    props: {
      // editedItem: {
      //   type: Object,
      //   required: true
      // },
      data: {
        type: Object,
        required: true
      },
      onDelete: {
        type: Function,
        required: true
      },
      onClose: {
        type: Function,
        required: true
      }
    },
    data () {
      return {
        url: null,
        editedItem: null
      }
    },
    computed: {        
      ...mapState('app', ['locale']),
      languages () {
        return this.$store.state.app.languages
      }
    },
    watch: {
      data () {
        this.editedItem = JSON.parse(JSON.stringify(this.data))
      }
    },
    // mounted() {
    //   api.getAllWithoutLimit ('dfiles', `private/files/${this.editedItem.ID}/${this.locale}/blob`)
    //     .then(response => {
    //       this.url = `${response.type === 'PDF' ? 'data:application/pdf;base64,' : ''}${response.data}`

    //       // var file = new Blob([response.Data.data], {type: 'application/pdf'})
    //       // var fileURL = URL.createObjectURL(file)
    //       // this.url = fileURL
    //       // console.log(fileURL)
    //       // window.open(fileURL)
    //     })
    // },
    mounted () {
      this.editedItem = JSON.parse(JSON.stringify(this.data))
    },
    methods: {
      handleDeleteFile (locale) {
        this.editedItem.FileInfo[locale] = null
      },
      handleUploadFile (v) {
        if (!this.editedItem.FileInfo) this.editedItem.FileInfo = {}
        for (const locale in v) {
          this.editedItem.FileInfo[locale] = {
            name: v[locale].name,
            size: v[locale].size,
            data: v[locale].data,
            type: v[locale].type
          }
        }
      },
      handleChangeIsModified () {
        // alert('aux')
      },
      handleSave () {
        if (!this.editedItem.ID)
          api.addItem ('dfiles', 'v1/private/files/', this.editedItem)
          .then((response) => {
            this.onClose('add', response.data && response.data.length > 0 ? response.data[0] : null)
          })
        else 
          api.updateItem ('dfiles', 'v1/private/files/', this.editedItem.ID, this.editedItem)
          .then((response) => {
            this.onClose('update', response.data && response.data.length > 0 ? response.data[0] : null)
          })
      }
    }
  }
</script>
