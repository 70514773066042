<template>
  <upload-file-item
    v-if="!translate"
    :localeFile="locale"
    :onChangeFile="handleChangeFile"
  />
  <v-dialog
    v-else
    v-model="dialog"
    persistent
    max-width="500"
  >
    <template v-slot:activator="{ }">
      <upload-file-item
        :info="info"
        :onOpenTranslate="translate ? handleOpenTranslate : null"
        :localeFile="locale"
        :onDeleteFile="onDeleteFile"
        :onChangeFile="handleChangeFile"
      />
    </template>
    <v-card>
      <!-- INI TABS -->
      <v-toolbar
        color="primary"
        dark
        tabs
        class="toolbarTabs"
      >
        <template v-slot:extension>
          <v-tabs
            v-model="tabs"
            centered
            slider-color="white"
            background-color="primary"
            dark
          >
            <v-tab
              v-for="item in languages"
              :key="item"
            >
              {{ item.toUpperCase() }}
            </v-tab>
          </v-tabs>
        </template>
      </v-toolbar>

      <v-tabs-items v-model="tabs">
        <v-tab-item
          v-for="item in languages"
          :key="item"
        >
          <v-card>
            <v-card-text>
              <upload-file-item
                :info="info"
                :localeFile="item"
                :onDeleteFile="onDeleteFile"
                :onChangeFile="handleChangeFile"
              />
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
      <!-- END TABS -->
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="primary"
          @click="dialog = false"
          style="margin-bottom:20px;"
        >
          {{ $t('common.close', locale) }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import { mapState } from 'vuex'
  import UploadFileItem from './UploadFileItem'
  export default {
    name: "upload-file",
    components: {
      UploadFileItem
    },
    props: {
      info: {
        type: Object,
        default: null
      },
      translate: {
        type: Boolean,
        default: false
      },
      onDeleteFile: {
        type: Function,
        requied: true
      },
      onChange: {
        type: Function,
        default: null
      },
      
    },

    data() {
      return {
        dialog: false,
        tabs: null,
        fileTranslate: {}
      }
    },
    computed: {
      ...mapState('app',['locale']),
      languages () {
        return this.$store.state.app.languages
      }
    },
    methods: {
      handleOpenTranslate () {
        this.dialog = true
      },
      handleChangeFile (file, locale) {
        if (this.translate) {
          this.fileTranslate[locale] = file
          if (this.onChange) this.onChange(this.fileTranslate)
        } else {
          if (this.onChange) this.onChange(file)
        }        
      },      
    }
  }
</script>
