<template>
  <v-card
    @drop.prevent="onDrop($event)"
    @dragover.prevent="dragover = true"
    @dragenter.prevent="dragover = true"
    @dragleave.prevent="dragover = false"
    :class="{ 'grey lighten-2': dragover }"
  >
    <v-card-text 
      @click="handleOpenFileSearch"
      style="cursor: pointer;"
    >
      <input
        type="file"
        accept=".pdf"
        v-show="false"
        ref="docpicker"
        @input="handleDocPickerChange"
      />
      <v-row class="d-flex flex-column" dense align="center" justify="center">
        <v-icon :class="[dragover ? 'mt-2, mb-6' : 'mt-5']" size="60">
          mdi-cloud-upload
        </v-icon>
        <!-- <p>
          Drop your file(s) here, or click to select them.
        </p> -->
        <p>
          {{ $t('folders.dropFile', locale)}}
        </p>
      </v-row>
      <v-virtual-scroll
        v-if="uploadedFiles.length > 0"
        :items="uploadedFiles"
        height="150"
        item-height="50"
      >
        <template v-slot:default="{ item }">
          <v-list-item :key="item.name">
            <v-list-item-content>
              <v-list-item-title>
                {{ item.name }}
                <span class="ml-3 text--secondary">
                  {{ item.size }} bytes</span
                >
              </v-list-item-title>
            </v-list-item-content>

            <v-list-item-action>
              <v-btn @click.stop="removeFile(item.name)" icon>
                <v-icon> mdi-close-circle </v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>

          <v-divider />
        </template>
      </v-virtual-scroll>
    </v-card-text>
    <v-card-actions>
      <!-- <v-spacer />

      <v-btn @click="closeDialog" icon>
        <v-icon id="close-button">mdi-close</v-icon>
      </v-btn>

      <v-btn icon @click.stop="submit">
        <v-icon id="upload-button">mdi-upload</v-icon>
      </v-btn> -->
      <v-btn v-if="onOpenTranslate" icon @click.stop="onOpenTranslate" color="primary">
        <v-icon id="upload-button">mdi-routes</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>    
</template>

<script>
  import { mapState } from 'vuex'
  export default {
    name: "upload-file-item",
    props: {
      info: {
        type: Object,
        default: null
      },
      localeFile: {
        type: String,
        default: null
      },
      onOpenTranslate: {
        type: Function,
        default: null
      },
      onDeleteFile: {
        type: Function,
        requied: true
      },
      onChangeFile: {
        type: Function,
        default: null
      },
      multiple: {
        type: Boolean,
        default: false
      },
    },
    data() {
      return {
        dragover: false,
        uploadedFiles: [],
      }
    },
    computed: {
      ...mapState('app',['locale']),
    },
    watch: {
      info () {
        this.convertInfo()
      }
    },
    mounted () {
      this.convertInfo()
    },
    methods: {
      convertInfo () {
        if (this.info && this.info[this.localeFile]) {
          if (this.uploadedFiles.length > 0) this.uploadedFiles = []          
          this.uploadedFiles.push(this.info[this.localeFile])
        } 
      },
      handleOpenFileSearch () {
        this.$refs.docpicker.click()
      },

      removeFile(fileName) {
        this.onDeleteFile(this.localeFile, fileName)
        // Find the index of the
        const index = this.uploadedFiles.findIndex(
          file => file.name === fileName
        )

        // If file is in uploaded files remove it
        if (index > -1) this.uploadedFiles.splice(index, 1)
      },

      onDrop(e) {
        this.dragover = false

        // If there are already uploaded files remove them
        if (this.uploadedFiles.length > 0) this.uploadedFiles = []

        // If user has uploaded multiple files but the component is not multiple throw error
        if (!this.multiple && e.dataTransfer.files.length > 1) {
          alert("Only one file can be uploaded at a time..")
          // this.$store.dispatch("addNotification", {
          //   message: "Only one file can be uploaded at a time..",
          //   colour: "error"
          // })
        }
        // Add each file to the array of uploaded files
        else
          e.dataTransfer.files.forEach(element =>
            this.uploadedFiles.push(element)
          )

        this.submit()
      },

      submit() {
        if (this.uploadedFiles.length > 0) {
          this.$emit("filesUploaded", this.uploadedFiles[0])
          this.handleChangeFile(this.uploadedFiles[0])
        }
      },
      
      handleDocPickerChange (e) {
        if (this.uploadedFiles.length > 0) this.uploadedFiles = []
        this.uploadedFiles.push(...e.target.files)
        this.handleChangeFile(this.uploadedFiles[0])
      },
      handleChangeFile (v) {
        const reader = new FileReader()
        reader.onloadend = () => {
          // use a regex to remove data url part
          const base64String = reader.result
            .replace("data:", "")
            .replace(/^.+,/, "")

          // log to console
          // logs wL2dvYWwgbW9yZ...
          // console.log(base64String)
          if(this.onChangeFile) this.onChangeFile({
            name: v.name,
            size: v.size,
            data: base64String,
            type: 'PDF'
          }, this.localeFile)
        }
        reader.readAsDataURL(v)
        // reader.readAsText(v)

        // if(this.onChangeFile) this.onChangeFile(v, this.localeFile)
      }
    }
  }
</script>
